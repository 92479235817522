/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import 'normalize.css';
import '../assets/styles/layout.css';
import '../assets/styles/app.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(filter: { slug: { eq: "sections/contact" } }) {
        edges {
          node {
            frontmatter {
              copyright
            }
          }
        }
      }
    }
  `);

  const { copyright } = data.allMdx.edges[0].node.frontmatter;

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main style={{ flex: 1 }}>{children}</main>
      <footer
        style={{
          background: `#2B3236`,
          color: `#fff`,
          fontFamily: `Libre Franklin`,
          fontSize: `12px`,
          padding: `0 0 0 12px`,
        }}
      >
        {`${copyright}, `}
        {new Date().getFullYear()}
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
